.title__header {
  font-size: 22px;
  font-weight: bold;
  color: #117170;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.newsAdministration__header {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 20px;
}

.search__input {
  border: 1px solid hsl(0, 0%, 80%);
  margin: 0px;
  width: 100%;
  padding: 9px;
  border-radius: 5px;
}

.container__toggleAndAdd {
  display: flex;
  column-gap: 158px;
  flex-wrap: wrap;
  margin-top: 18px;
}

.newsAdministration__toggle {
  position: relative;
  height: 34px;
}

.dropdown__clients {
  position: relative;
  display: inline-block;
  /* width: 16%;
   */
  width: 24%;
  margin-top: 8px;
}

.dropdown-toggleMyAlerts {
  padding: 8px 8px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  margin-top: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6f6c6c;
}

.customer_name {
  margin-left: 10px;
  word-wrap: break-word;
}

.subdomain_name {
  margin-left: 10px;
}

.toggle__orderInput[type='checkbox'] {
  display: none;
}

.toggle__sliderMyAlerts {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #005A24;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.4s;
}

.toggle__sliderMyAlerts:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.toggle__orderInput[type='checkbox']:checked+.toggle__sliderMyAlerts {
  background-color: #bdc1bf;
}

.toggle__orderInput[type='checkbox']:checked+.toggle__sliderMyAlerts:before {
  left: calc(100% - 30px);
}

.toggle__active {
  text-align: end;
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 600;
}

.toggle__disabled {
  margin-top: 5px;
  margin-left: 7px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.container__pagination {
  margin-top: 30px;
}

.container__totalNews {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.showing__totalNews {
  color: #656464;
}

.alert-grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 19px;
  padding: 8px;
}

.alert-card {
  background-color: #FFFFFF;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
}

.alert-title {
  margin-bottom: 6px;
  font-size: 19px;
}

.alert-description {
  color: #7b7676;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.alert-severity {
  color: #FF4136;
  font-weight: bold;
  margin-bottom: 5px;
}

.alert-date {
  color: #AAAAAA;
  font-size: 0.8em;
  margin-top: 10px;
}
.container__severityMyAlerts {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.severity__severityMyAlerts {
  padding: 0.5em;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
}
.title__sortByMyAlerts {
  margin-top: 20px;
  margin-bottom: -7px;
  font-weight: 600;
}
.show__cart {
  display: flex;
  justify-content:end;
  align-content: center;
  cursor: pointer;
  color: rgb(6, 172, 56);
  font-weight: bold;
}
.show__cart:hover {
  color: rgb(7, 77, 28);
} 
.button__downloadHealthCheckAlerts {
  padding: 8px ;
  border-radius: 8px ;
  background-color: rgba(4, 138, 36, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  display: flex;
  border: none;
}
.button__downloadHealthCheckAlerts:disabled {
  opacity: 0.8;
}
.container__buttonsFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container__toggleDownload{
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.container__clearSearch {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.text__clear {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: rgba(91, 91, 91, 1);
  cursor: pointer;
}
.show__cartTitle {
  font-weight: 600;
  font-size: 15px;
}
.container__cartAlert {
  height: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  margin-right: 13px;
}
.total__cartAlert {
  border-radius: 1px solid red;
  background: #ec4733;
  border: 1px solid #fff;
  border-radius: 5rem;
  font-size: 12px;
  width: 1.3rem;
  height: 1.3rem;
  padding: 0;
  line-height: 1rem;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: -8px;
  left: 21px;
  color:#fff
}
.icon__cartAlert {
  position: relative;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .alert-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .alertsSummary, .entitySeverity {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .alert-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .alert-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 720px) {
  .newsAdministration__toggle {
    margin-bottom: 10px;
  }

  .container__toggleAndAdd {
    width: 50%;
  }
}



.container__newsAdministration {
  background-color: #e5f2e9;
  padding: 1em;
  height: 100%;
  border-radius: 5px;
  text-align: left;
  overflow-y: auto;
  font-family: "Plain-Regular";
}

.container__alertAdministration {
  display: flex;
}

.first-div {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 20px;
  flex: 20%;
  height: calc(80vh - 66px);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.closed__section {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 20px;
  width: 7%;
  height: 80vh;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 10px;
}

.closed__sectionIcon {
  margin-left: 11px;
}

.second-div {
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 12px #d7d7d7;
  margin-top: 10px;
  padding: 1px;
  flex: 83%;
  /* margin-left: 10px; */
  height: 80vh;
  margin-bottom: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.icon__caretDownAlert {
  margin-left: 90px
}

.dropdown-menuMyAlerts {
  display: block;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
  height: 300px;
  overflow-y: scroll;
  width: 100%;
  padding: 12px;
}

.dropdown-menuMyAlerts li {
  padding: 8px 0px;
}

.dropdown-menuMyAlerts li label {
  display: block;
  cursor: pointer;
}

.dropdown-menuMyAlerts li label:has(input[disabled]) {
  cursor: not-allowed;
}

.container__closeHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;
}

.icon__closeAlert {
  margin-right: 5px;
}

.btn__requestSupport {
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin-top: 3px;
}
.addCart__Alert {
  background-color: rgb(6, 172, 56);
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  padding: 3px;
  font-size: 13px;
  font-weight: 600;
}
.addCart__container {
  display: flex;
  justify-content: end;
  margin-bottom: 8px;
}

.container__dataNotFoundAlerts {
  text-align: center;
  position: relative;
  top: 40%
}

.icon__hide {
  margin-right: 10px;
}

.container__entity {
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #585656;
  width: auto;
  display: flex;
  margin-bottom: 15px;
  width: 160px;
}

.icon__entity {
  margin-right: 10px;
  margin-left: 3px;
}

.myAlertsAppReturnButton {
  width: 100%;
  border: none;
  background-color: transparent;
  color: grey;
  margin-top: auto;
}

.container__filtersAlert {
  border-radius: 16px;
  padding: 16px;
  background-color: rgba(250, 250, 250, 1);
  flex-direction: column;
  display: flex;
  gap: 24px;
}
.container__searchWrapper {
  display: flex;
}
.input__containerNewsAdministration {
  width: 100%;
}
.btn__search {
  border: 1px solid rgb(6, 172, 56);
  border-radius: 5px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  margin-left: 10px;
}
.btn__search:hover {
  background-color: rgb(3, 109, 35);
  color: #fff;
  cursor: pointer;
} 
.container__filterAlerts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 9px;
}
.container__paginationAlerts {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 8px; */
  column-gap: 22px;
  align-items: center;
}
.container__downloadClear{
  display: flex;
  justify-content: space-between;
}
.container__newsAdministrationAlerts {
  border-radius: 5px;
  text-align: left;
  font-family: "Plain-Regular";
  padding-top: 0.4em;
}
.entity__first {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
}
.spinner__alerts {
  --clr: rgb(0, 113, 128);
  --gap: 6px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
 }
 .spinner__alerts span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
 }
 .spinner__alerts span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
 }
 .spinner__alerts span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
 }
 .spinner__alerts span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
 }
 .container__paginationAlerts >.pagination__solutionGallery {
  background-color: rgb(248 248 248) !important;
  border: none;
}
.description__newAlert {
  margin-bottom: 2px !important;
}
.title__homeAlert {
  overflow: hidden;
  white-space: normal;
  margin-top: 3px;
  margin-bottom: 8px;
  font-size: 14px;
  width: 90%;
}
.status__alerts {
  padding: 0.3em;
  border-radius: 4px;
  font-weight: bold;
}
.container__detailAlert{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(17 24 39 / 0.5);
  z-index: 1000;
}
.container__sectionAlert{
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  width: 60%;
  top: 50%;
  max-height: 98vh;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border-radius: 0.5rem; */
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.modal__containerDetailAlert {
  padding: 0px;
  height: 100vh;
}
.header__cartAlerts {
  background-color: #06AC38;
  padding: 11px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #ffff
}
.clearCart__alertsContainer {
  display: flex;
  justify-content:space-between;
  padding: 11px;
  color: #06AC38;
  /* margin-top: 10px; */
}
.clearCart__alerts {
  color: #06AC38;
  cursor: pointer;
}
.clearCart__alerts:hover {
  color: #046020;
}
.icon__clearCartAlert {
  margin-left: 10px;
}

.container__notCartAlert {
  transform: translate(-50%, 10%) !important;
}
.alerts__cartContainer {
  padding: 10px;
  height: 50%;
  overflow-y: scroll;
  /* border-bottom: 1px solid hsl(0, 0%, 80%); */

}
.title__homeAlertCart {
  overflow: hidden;
  white-space: normal;
  margin-top: -4px;
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.pd__entities {
  margin-bottom: 8px;
}
.meta__homeCart {
  margin-bottom: 0px;
}
.container__requestUrgency {
  padding: 10px;
}
.container__requestDetail {
  padding: 0px 10px 10px 10px;
}
.btn__sendCartAlert {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.textarea__requestDetailAlert {
  margin-left: 0px;
  border: 1px solid #002b4f;
  border-radius: 5px;
  margin-top: 7px;
  height: 70px;
  width: 100%;
}
.div__border{
  border-bottom: 1px solid hsl(0, 0%, 80%);
}
.container__imgUserCartAlert {
  position: relative;
  height: 140px;
  width: 140px;
  background-color: #117170;
  border-radius: 50%;
  border: 10px solid white;
  overflow: hidden;
  box-shadow: 0px 0px 5px -1px black;
  margin-bottom: 10px;
  left: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 67%;
}
.img__pagerUserCartAlert {
  height: 125px;
  right: -3px;
  position: absolute;
}
.container__thankYouAlert {
  display: flex;
  justify-content: center;
  margin-top: 18%;
 }
 .spinner__containerAlert {
  width: 70px;
  margin-right: 10px; 
}
.btn__viewGwpSra {
  /* display: flex;
  justify-content: space-between; */
}
.name__textGwpSra {
  /* padding: 6px;
  font-size: 14px;
  background-color: #f2e8cf;
  border-radius: 5px;
  color: #08812c; */
  border: 1px solid #f2e8cf;
  border-radius: 5px;
  background-color: #f2e8cf;
  color: #08812c;
  font-size: 12px;
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
  /* height: 30px; */
  width: 100%;
  text-align: center;
  padding-top: 3px;
  /* margin-top: 8px; */
}
.container__steps {
  padding: 0px 10px 10px 10px;
}
.steps__title{
  background-color: #ACF07D;
  padding: 4px 24px 10px 10px;
  position: relative;
  font-weight: 600;
  height: 34px;
  display: flex;
  justify-content: space-between;
}
.effort__description {
  margin-left: 10px;
}
.steps__title::after {
  content: '';
  position: absolute;
  top: -2%;
  right: -13px;
  width: 0;
  height: -11px;
  border-top: 16px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #ACF07D;
  height: 35px;
}
.next_steps {
  margin-top: 10px;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  list-style-type: disc; 
}
.description__steps {
  padding: 0px 10px 10px 10px;
  margin-bottom: 14px;
}
.title__steps {
  background-color: #ACF07D;
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(0, 128, 0, 0.6);
  font-weight: 600;
  margin-bottom: 22px;
}
.ul__steps {
  padding: 0px 10px 10px 23px;
}
.not__foundGwp {
  font-size: 18px;
  text-align: center;
  color: #444242b3;
}
.tooltip-containerAlert {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
  color: #585656;
  width: auto;
}
.tooltip-containerAlert:hover .tooltip__alerts {
  bottom: 100%; 
  opacity: 100;
  visibility: visible;
  pointer-events: auto;
  transform: translateX(-50%) scale(1);
  font-weight: 800;
  width: 161px;
  background-color: rgb(6, 172, 56);
  color: #fff;
}
.tooltip__alerts {
  position: absolute;
  bottom: 100%;
  left: 90%;
  transform: translateX(-50%) scale(0.8);
  transform-origin: top; 
  padding: 0.3em 0.6em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: 4px;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
}
.icon__caretDownAlertRequestUrgency {
  position: absolute;
  right: 17px;
  transform: translateY(-50%);
  top: 24px;
}
.container__urgencySelect {
  position: relative;
}
.name__textGwpSra:disabled {
  opacity: 0.5;
}
.label__inputFilter {
  color: #6f6c6c;
  margin-bottom: 2px;
  font-weight: bold;
}
.label__multiCheckboxinputFilter {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
}
.buttons__selectSession {
  margin-bottom: 7px;
  padding: 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container__buttons--selectSession {
  display: flex;
  justify-content: center;
}
.selectSession__button {
  padding: 6px 16px 6px 12px;
  border-radius: 8px 8px 0px 0px;
  cursor: pointer;
  border-left: solid 1px rgba(233, 233, 233, 1); 
  border-top: solid 1px rgba(233, 233, 233, 1);
  border-right: none;  
  border-bottom: none; 
  background: rgba(255, 255, 255, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.button__andSpinner {
  padding: 0px;
  display: flex;
  cursor: pointer;
}
.disabled__selectSession{
  padding: 6px 16px 6px 12px;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
  border: 0px 1px 0px 1px;
  cursor: pointer;
  background: rgba(244, 244, 244, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color:  rgba(100, 100, 100, 1);
}
.header__infoDashboard {
  display: flex;
  justify-content: space-between;
  margin-left: 17px;
  width: 100%;
}
.welcome__dashboard {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 3px;
  color: #ffff
}
.number__totalAlert {
  color: #797979;
  margin-bottom: 3px;
  font-weight: 100;
  color: #ffff
}
.title__totalAlert {
  font-weight: 600;
  font-size: 15px;
  color: #ffff
}
.container__buttonDownload {
  display: flex;
  column-gap: 8px;
}
.buttonDownload {
  padding: 4px;
  border: none;
}
.container__alertsSummary {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 .75rem 6rem rgba(56, 65, 74, .03);
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: fit-content;
  justify-content: center;
  padding: 8px 13px 7px 10px;
  width: 49%;
}
.title__sessionDashboard {
  font-size: 18px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 32px;
}
.container__alertsSummaryEntitySeverity {
  display: flex;
  column-gap: 27px;
  flex-wrap: wrap;
}
.alertsSummary, .entitySeverity {
  /* width: 49%; */
  width: 100%;
}
.entitySeverity {
  margin-top: 12px;
}
.header__myAlerts {
  margin-bottom: 7px;
  border-radius: 8px !important;
  background-image: url('../../assets/wallpaper1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.tooltip__containerAlert {
  background-color:rgb(6, 172, 56);;
  text-align: center;
  padding: 4px;
  cursor: auto;
  width: 120px;
}
.entities_labels {
  font-weight: 600;
  font-size: 13px;
  color: #FFFFFF;
}
.total__entities {
  color: #FFFFFF;
}
.th__dashboard {
  background-color:rgb(6, 172, 56);
  color: #ffff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table__dashboard {
  width: 100%;
  margin-top: 15px;
  text-align: center;
}
.td__dashboard {
  border-bottom: 1px solid #e3dfdf;
}
.td__dashboardInfo {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pdEntities_labels {
  font-weight: 600;
}
.odd-row {
  background-color: #FAFAFA;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.header__table {
  padding-top: 5px;
  padding-bottom: 5px;
}
.container__chartDashboard {
  display: flex;
  justify-content: center;
  margin-top: 19px;

}
.info__summaryStatus {
  margin-right: 48px;
}
.name__status {
  font-size: 17px;
  font-weight: 600;
}
.total__infoStatus {
  color: #7f7e7ecf;
  margin-left: 10px
}
.container__titleAndInfo {
  display: flex;
  justify-content: space-between;
}
.icon__infoMyAlert {
  color: rgb(6, 172, 56);
  background: none;
  border: none;
  height: 0px;
}
.icon__infoMyAlert:disabled {
  opacity: 0.5;
}
.container__yesAndNot {
  margin-top: 10px;
  margin-left: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px;
  top: 174px;
  left: 1000px;
}
.buttons__yesNot {
  text-align: center;
  margin-top: 10px;
  display: flex;
  column-gap: 10px;
}
.btn__search:disabled {
  opacity: 0.5;
}
.imgPagerDuty {
  margin-top: 8px;
}
.container__entityNameDashboard {
  display: flex;
  column-gap: 2px;
  margin-bottom: 14px;
  justify-content: center;
}
.select__entityName {
  border-radius: 5px 5px 0px 0px;
  background-color: rgb(6, 172, 56);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  margin-left: 4px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}
.select__entityNameNot {
  border-radius: 5px 5px 0px 0px;
  box-shadow: 14px -7px 57px -20px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  /* border-radius: 3px; */
  background-color: #F0F0F0;
  color: rgb(6, 172, 56);
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  margin-left: 4px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}
.container__modalHealthCheckAlert {
  padding: 15px;
}
.pagerDutyObject {
  background-color: #FA640A;
  padding: 5px;
  color: #fff;
  width: fit-content;
}
.healthCheckRule {
  padding: 5px;
  color: #fff;
  width: fit-content;
  background-color: #086405;
  margin: 9px 0;
}
.status__modalHealthCheckAlert {
  background-color: #086405;
  padding: 5px;
  color: #fff;
  width: 100%;
}
.header__modalHealthCheckAlert {
  background-color: #086405;
  padding: 11px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  color: #ffff;
  font-size: 20px;
}
.container__infoModalHealthCheckAlert {
  margin-top: 20px;
}
.title__infoModalHealthCheckAlert {
  color: #086405;
  font-weight: 600;
  margin-bottom: 3px;
  font-size: 18px;
}
.description__infoModalHealthCheckAlert {
  color: #565656;
  margin-bottom: 15px;
}
.analysis__infoModalHealthCheckAlert {
  margin-bottom: 15px;
  margin-top: 15px;
}
.healthCheckAlert__ul {
  list-style-type: circle;
  padding: 10px 20px 2px 20px;
}
.spinner__download {
  height: auto;
  --clr: #ffff;
  margin-left: 10px;
}
.textGwpSra__link {
  color: #007bff; 
  text-decoration: underline;
  cursor: pointer;
}
.textGwpSra__link:hover {
  color: #0056b3; 
  text-decoration: none; 
}
.small-svgAlerts {
  width: 2em; 
  transform-origin: center;
  animation: rotate4 2s linear infinite;
  margin-left: 10px;
}

.small-svgAlerts circle {
  fill: none;
  stroke: hsl(0, 0%, 94%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}
.text__urgency {
  margin-bottom: 10px;
}
.title__requestedFilter {
  font-size: 17px;
  color: #515050;
  font-weight: bold;
}
.container__backDashboard {
  margin-bottom: 7px;
  color:#6f6c6c;;
  cursor: pointer;
}
.fa__ArrowLeft {
  margin-right: 10px;
}
.not__haveHistory {
  color: #fff;
  margin-top: 10px;
}
.placeholder__select {
  font-size: 13px;
  color: #6d6868;
}
.container__tooltipMultiCheckbox {
  display: flex;
}
.tooltip-containerMultiCheck {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
  color: #585656;
  width: auto;
  margin-left: 10px;
}
.tooltip__multiCheck {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  transform-origin: top;
  padding: 0.3em 0.6em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  border-radius: 4px;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
}
.tooltip-containerMultiCheck:hover .tooltip__multiCheck {
  bottom: 20px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateX(-50%) scale(1);
  font-weight: 800;
  width: 161px;
  background-color: rgba(4, 138, 36, 1);
  color: #ffff;
}
.href__omm {
  color: #ffff;
}
.icon__multiCheck {
  color: rgb(6, 172, 56);
}
.bullet-item {
  position: relative;
  padding-left: 20px; 
  margin-top: 2px; 
}

.bullet-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 7px; 
  height: 7px; 
  background-color: #565656;
  border-radius: 50%; 
}
.container__japan{
  margin-top: 50px;
  padding: 50px;
  text-align: center;
}
.total__alertsDropdown {
  color: #938c8c;
  margin-bottom: 2px;
  font-weight: bold;
}
.input__filter {
  margin: 0;
  padding: 5px;
}
.newsAdministration__toggle {
  position: relative;
  width: 100px;
  height: 34px;
}
.container__notAlerts {
  display: flex;
  justify-content: center;
  width: 100%;
}
.container__imgNoAlerts {
  width: 500px;
}
.text__noAlert {
  text-align: center;
  font-size: 25px;
  color: #002b4f;
  font-weight: 700;
}
.container__healthCheckAlerts {
  display: flex;
  gap:24px;
  flex-direction: column;
  height: 100%;
}
.healthCheckAlerts__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.healthCheckAlerts__headerDate {
  gap: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerDate__alert {
  color: rgba(135, 135, 135, 1);
  font-size: 14px;
  font-weight: 400;
}
.creation_date {
  margin-left: 8px;
}
.healthCheckAlerts__body {
  display: flex;
  gap:16px;
  flex-direction: column;
  flex-grow: 1
}
.body__entity {
  display: flex;
  gap: 9px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.5px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.body__descriptionAlert{
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(83, 83, 83, 1)
}
.descriptionAlert__currentValue {
  font-size: 14px;
  font-weight: 375;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
}
.expected__value {
  color: rgba(83, 83, 83, 1);
}
.container__buttonsCardAlerts {
  margin-top: auto; 
  display: flex;
  flex-direction: column;
  gap: 8px
}
.name__viewSRA:disabled {
  opacity: 0.5;
  background: none !important;
  color: rgba(91, 91, 91, 1)
}
.name__viewSRA {
  width: 100%;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid  rgba(91, 91, 91, 1);
  color:  rgba(91, 91, 91, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  background: none;
}
.button__getWellPlan {
  width: 100%;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid  rgba(4, 138, 36, 1);
  color:rgba(4, 138, 36, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;
  padding-left: 26px;
}
.icon__rocket {
  display: inline-block;
  position: relative;
  top: 2px;
}
.btn__serviceRequest {
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  background: rgba(4, 138, 36, 1);
  border:none;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: rgba(255, 255, 255, 1);
}
.item__omm {
  margin-top: 10px;
  padding: 4px 8px 4px 8px;
  border-radius: 16px;
  font-size: 10px;
  font-weight: 375;
  line-height: 15px;
}
.container__omm {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
}
.container__paginationAlert {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.button__getWellPlan:hover, .name__viewSRA:hover, .btn__serviceRequest:hover {
  transform: translateY(-2px);
}
.container__alertsFound {
  display: flex;
  justify-content: center;
  gap:9px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 375;
  line-height: 19.6px;
  color:rgba(160, 174, 192, 1);
}