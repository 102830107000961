:root {
  --tfer-app-title-height: 60px;
  --tfer-app-sub-title-height: 48px;
  --tfer-app-navigation-height: 46px;
}

#tfer-app__container {
  margin: 20px 25px;
  margin-bottom: 75px;
  font-family: "Plain-Regular";
}

#tfer-app__title,
#tfer-app__sub-title,
#tfer-app__terraformation-mode,
#tfer-app__container .tfer-app__main,
#tfer-app__container .tfer-app__show-plan-container,
#tfer-app__container .tfer-app__show-plan-container h5,
#tfer-app__container .tfer-app__terraformation-progress-container,
#tfer-app__container .tfer-app__customer-info,
#tfer-app__container .tfer-app__line {
  margin-bottom: 20px;
}

#tfer-app__container .tfer-app--flex {
  display: flex;
}

#tfer-app__container .tfer-app--flex-align-center {
  justify-content: center;
  align-items: center;
}

#tfer-app__container .tfer-app--flex-align-right {
  flex-direction: row;
  align-items: flex-end;
}

#tfer-app__faq {
  text-align: right;
  cursor: pointer;
  z-index: 3;
}

#tfer-app__title {
  position: relative;
  padding-top: 1px;
  min-height: var(--tfer-app-title-height);
}

#tfer-app__container .tfer-app__customer-info {
  font-size: 12px;
  margin-top: 5px;
  margin-left: 15px;
}

#tfer-app__sub-title {
  min-height: var(--tfer-app-sub-title-height);
}

#tfer-app__sub-title > p {
  color: #6f6c6c;
  font-size: 1em;
  margin: 0;
  padding-left: 15px;
  max-width: 1200px;
}

#tfer-app__container .tfer-app__caption {
  margin-top: 4px;
  color: #06ac38;
  color: #0d47a1;
  font-size: 0.92em;
}

#tfer-app__terraformation-mode-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  /*
  height: var(--tfer-app-navigation-height);
  */
}

#tfer-app__terraformation-mode-container .banner {
  margin: 0;
}

#tfer-app__terraformation-mode-container button {
  margin-right: 15px;
}

#tfer-app__terraformation-mode-container button:last-child {
  margin-right: 0;
}

#tfer-app__terraformation-mode-container .pills-list {
  display: flex;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  background-color: #dee2e9;
}

#tfer-app__terraformation-mode-container .pill-list-item,
#tfer-app__terraformation-mode-container .pill-list-item-active {
  cursor: default;
  display: inline-block;
  font-weight: bold;
  line-height: 100%;
  list-style: none;
  margin: 3px 3px;
  margin-bottom: 3px !important; /* override TipTapComponent */
  padding: 0.75rem 1rem;
}

#tfer-app__terraformation-mode-container .pill-list-item {
  color: #8f9baa;
}

#tfer-app__terraformation-mode-container .pill-list-item-active {
  color: #505c68;
  background-color: #fff;
  border-radius: 10px;
}

#tfer-app__container .tfer-app__content-container {
  min-height: 600px;
  min-height: calc(100vh - 320px);
}

#tfer-app__container .tab-list {
  border: 0;
  padding-left: 0;
  justify-content: center;
}

#tfer-app__container .tab-list-item,
#tfer-app__container .tab-list-item-active {
  color: #646464;
  font-weight: normal;
  border: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
#tfer-app__container .tab-list-item-active {
  background-color: #fff;
}
#tfer-app__container .tab-list-item > svg,
#tfer-app__container .tab-list-item-active > svg {
  font-size: 0.8em;
  margin-right: 1ex;
}

#tfer-app__container
  .tfer-app__content-container
  .tfer-app__tab-content-container {
  background-color: #fff;
  padding: 32px;
  border-radius: 16px;
}

#tfer-app__container .tfer-app--center-content {
  padding: 10px;
}

#tfer-app__container .tfer-app__account-picker {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

#tfer-app__container .tfer-app__separated {
  margin-top: 40px;
  margin-bottom: 0;
}

#tfer-app__container .tfer-app__resources-picker {
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
}

#tfer-app__container .tfer-app__resources-picker .container__filterAlerts {
  margin: 0;
  /* height: calc(58px + 26px + 3 * 48px); */
  height: 228px;
}

#tfer-app__container .container__tooltipMultiCheckbox {
  justify-content: space-between;
}

#tfer-app__container .label__inputFilter {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  font-size: 14px;
  margin-bottom: 8px;
}

#tfer-app__container .tfer-app__select-all {
  flex-shrink: 0;
  font-size: 14px;
  accent-color: #048a24;
}

#tfer-app__container .tfer-app__resources-picker .dropdown-menuMyAlerts__wrap {
  position: relative;
}

#tfer-app__container .search__subdomainHomeFilter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin: 0;
  padding: 14px 16px;
  font-size: 0.92em;
}

#tfer-app__container .search__subdomainHomeFilter::placeholder {
  color: #9c9c9c;
}

#tfer-app__container .tfer-app__resources-picker .dropdown-menuMyAlerts {
  overflow: unset;
  height: unset;
  padding: 64px 0 12px;
  border: none;
}

#tfer-app__container .tfer-app__resources-picker .dropdown-menuMyAlerts > ul {
  padding: 16px;
  margin: 0;
  overflow-y: auto;
  height: 132px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}

#tfer-app__container .tfer-app__resources-picker .dropdown-menuMyAlerts li {
  color: #828282;
  color: #414141;
  font-size: 14px;
  padding: 0;
  list-style: none;
}

#tfer-app__container .tfer-app__resources-picker .dropdown-menuMyAlerts input {
  accent-color: #048a24;
}

#tfer-app__container
  .tfer-app__resources-picker
  .dropdown-menuMyAlerts:has(input[disabled]) {
  background-color: #fafafa;
  color: #afafaf;
}

#tfer-app__container .tfer-app__resources-picker .dropdown__clients {
  width: 100%;
  margin: 0;
}

.tfer-app__nowrap {
  white-space: nowrap;
}

@media (min-width: 768px) {
  #tfer-app__container .tfer-app__resources-picker {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 992px) {
  #tfer-app__faq {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 17px;
  }
  #tfer-app__faq #PSStatus-FAQIcon {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  #tfer-app__container .tfer-app__resources-picker {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

#tfer-app__container .container__buttonFilter .item__filter {
  padding: 3px 6px;
}

#tfer-app__container .container__buttonFilter .item__filter > svg {
  margin-left: 3px;
}

#tfer-app__container .dropdown-toggleButtonSolutionCategories::after {
  display: none;
}

#tfer-app__container
  .tfer-app__show-plan-container
  .tfer-app__show-plan-list-container {
  overflow-y: auto;
}

#tfer-app__container .tfer-app__show-plan-container,
#tfer-app__container .tfer-app__terraformation-progress-container,
#tfer-app__container .tfer-app__logs-container {
  position: relative;
}

#tfer-app__container .tfer-app__show-plan-container ul li {
  color: #016080;
  list-style: none;
  list-style-type: disclosure-closed;
  list-style-type: square;
  margin-left: 50px;
  font-size: 18px;
}

#tfer-app__container
  .tfer-app__show-plan-container
  ul.tfer-app__show-plan-list
  li {
  color: #048a24;
  list-style: none;
  list-style-type: disclosure-open;
  margin-left: 50px;
}

#tfer-app__container
  .tfer-app__show-plan-container
  ul.tfer-app__show-plan-list
  li.tf-app__show-plan-sub-item {
  color: #000;
  list-style-type: disc;
  margin-left: 80px;
}

#tfer-app__container .tfer-app__show-plan-list a {
  color: #016080;
}

#tfer-app__container .tfer-app__terraformation-progress-status {
  text-align: center;
  margin-bottom: 20px;
}

#tfer-app__container .tfer-app__terraformation-progress-loading-pagey {
  display: inline-block;
  cursor: pointer;
  width: 240px;
  overflow: hidden;
}

#tfer-app__container .tfer-app__terraformation-progress-loading-wrapper {
  margin-left: -180px;
  /* (600px - 240px) / 2 */
}

#tfer-app__container .tfer-app__terraformation-progress-loading-pagey,
#tfer-app__container .tfer-app__terraformation-progress-loading-caption {
  cursor: pointer;
}

#tfer-app__container .tfer-app__terraformation-progress-ok,
#tfer-app__container .tfer-app__terraformation-progress-failed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 405px;
}

#tfer-app__container .tfer-app__terraformation-progress-ok svg,
#tfer-app__container .tfer-app__terraformation-progress-failed svg {
  height: 68px;
  width: 68px;
  margin: 8px;
}

#tfer-app__container .tfer-app__terraformation-progress-ok svg {
  color: #016080;
}

#tfer-app__container .tfer-app__terraformation-progress-failed svg {
  color: rgba(var(--bs-danger-rgb));
}

#tfer-app__container .tfer-app__terraformation-feedback-request {
  color: #016080;
  margin-top: 50px;
}

#tfer-app__container .tfer-app__terraformation-progress-show {
  text-align: center;
}

#tfer-app__container .tfer-app__plan-btn-container {
  text-align: right;
  margin-bottom: 32px;
}

#tfer-app__container .tfer-app__btn {
  padding: 11px 16px;
  border-radius: 10px;
  text-decoration: none;
  border: none;
  font-weight: 500;
  background-color: #fff;
  white-space: nowrap;
  color: #048a24;
}

#tfer-app__container .tfer-app__btn:hover {
  opacity: 0.9;
}

#tfer-app__container .tfer-app__btn.tfer-app__btn--featured {
  color: #fff;
  background-color: #016080;
  background-color: #048a24;
}

#tfer-app__container .tfer-app__btn.tfer-app__btn--pagerduty {
  color: #048a24;
  background-color: #fff;
  border: 1px solid #048a24;
}

#tfer-app__container .tfer-app__btn.tfer-app__btn--generate-tf,
#tfer-app__container .tfer-app__btn.tfer-app__btn--download-tf {
  color: #fff;
  font-weight: 700;
  background-color: #03ac37;
}

#tfer-app__container .tfer-app__btn:disabled,
#tfer-app__container .tfer-app__btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tfer-app--warning-text {
  color: #f00;
}

#tfer-app__container .tfer-app__logs-container {
  width: 100%;
}

#tfer-app__container .tfer-app__logs-container-pre {
  background-color: #111;
  color: white;
  height: 625px;
  margin: 0;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 1rem 2rem;
  scroll-behavior: smooth;
}

#tfer-app__container .tfer-app__logs-container-pre-close {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
  color: #fff;
}

#tfer-app__container .tfer-app__logs-container-pre .red {
  color: red !important;
}

#tfer-app__container .tfer-app__logs-container-pre .yellow {
  color: yellow !important;
}

#tfer-app__container .tfer-app__logs-container-pre .green {
  color: green !important;
}

#tfer-app__container .tfer-app__logs-container-pre .gray {
  color: #777 !important;
}

#tfer-app__container .tfer-app__logs-container-pre .bold {
  font-weight: bold !important;
}
#tfer-app__container .tfer-app__logs-container-pre .underline {
  text-decoration: underline !important;
}

#tfer-app__container .tfer-app--logs-pending {
  animation: 500ms blink infinite alternate;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

#tfer-app__container .tfer-app__logs-output {
  color: #fff;
  text-wrap: wrap;
}

.tfer-keyword {
  color: inherit;
  white-space: nowrap;
  margin-left: 2px;
  margin-right: 2px;
}

.text-unfeatured {
  color: #a4b1a8;
  color: #adadad;
}

.tfer-app__capitalize {
  text-transform: lowercase;
}

.tfer-app__capitalize::first-letter {
  text-transform: uppercase;
}

#tfer-app__container .tfer-app__main {
  min-height: calc(100vh - 100px);
  position: relative;
}

#tfer-app__container .tfer-app__account-picker {
  position: relative;
}

#tfer-app__container .dropdown-menuClient {
  width: 100%;
}

#tfer-app__container .dropdown-menuClient li {
  padding: 4px 6px;
}

#tfer-app__container .dropdown-menuClient li label {
  padding: 4px 6px;
  display: block;
  cursor: pointer;
}

#tfer-app__container .dropdown-toggleButton {
  border-color: #ccc;
}

#tfer-app__container .dropdown-toggleButton[disabled] {
  background-color: #fafafa !important;
  /* there's an !important over a native element in src/components/PremiumRequestModal/PremiumRequestModal.css lol */
}

#tfer-app__container .dropdown-toggleButton::after {
  transition: transform 400ms;
  color: #ccc;
}

#tfer-app__container .dropdown-toggleButton.is-open::after {
  transform: rotate(-180deg);
}

@keyframes tfer-app__spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tfer-app__spin {
  animation: tfer-app__spin 1s linear infinite;
}

#tfer-app__container .beta-tag {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 3px;
  background-color: rgb(148, 178, 165);
}

#tfer-app__container .tfer-app__subdomain-picker-select {
  border-radius: 0.25rem;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 8px;
  margin-right: 16px;
  width: 100%;
}

#tfer-app__container form {
  display: unset;
}

#tfer-app__container .tfer-app__subdomain-picker-select.tfer-app__is-empty {
  color: gray;
}

#tfer-app__container .tfer-app__multicheckbox-search-skeleton {
  margin-top: 29px;
  height: 52px;
  border-radius: 8px;
  margin-bottom: 12px;
}

#tfer-app__container .tfer-app__multicheckbox-body-skeleton {
  height: 132px;
  border-radius: 8px;
}
