.tiptapComponent ul {
    list-style-type: disc!important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.tiptapComponent li{
    margin-bottom: 0px !important;
}

.tiptapComponent li p {
    margin-bottom: 8px;
}
.tiptap {
    > * + * {
      margin-top: 0.75em;
    }
  
    ul,
    ol {
      padding: 0 1rem;
    }
  
    h1 {
      font-size: 1.6em;
      font-weight: bold;
    }
    h2 {
      font-size: 1.2em;
      font-weight: bold;
    }
    h3 {
      font-size: 1em;
      font-weight: bold;
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  
    blockquote {
      padding-left: 1rem;
      border-left: 2px solid #ddd;
    }
  }
  .flex {
    display: flex;
  }
  .activeButton {
    color: rgb(4, 138, 36);
  }
  .tiptapButton {
    border: none;
    background-color: transparent;
    &:hover {
        color: #646464;
    }
  }
  .inputColor {
    width: 30px;
    height: 30px;
    padding: 0 !important;
    border: none;
  }
  .rounded-lg {
    border-radius: 8px;
  }
  .editorContentTipTap .ProseMirror {
    min-height: 120px;
  }
  .tiptapIconBar {
    svg {
      height: 1.2em;
      display: flex;
    }
  }