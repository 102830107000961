.banner {
  width: 100%;
  padding: 10px 0;
  color: #000;
  text-align: start;
  font-weight: bold;
  position: relative;
}

.banner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.maintenance-banner-icon {
  margin-right: 20px;
  height: 1.5em;
}

.banner-info {
  background-color: #e3f2fd;
  color: #0d47a1;
}

.banner-warning {
  background-color: #fff3e0;
  color: #e65100;
}

.banner-error {
  background-color: #ffebee;
  color: #b71c1c;
  border: 1px solid #f44336;
}

.banner-success {
  background-color: #e8f5e9;
  color: #1b5e20;
}

/*.banner-error-modern ,*/
.banner-warning-modern {
  border-width: 1px;
  border-style: solid;
  text-align: left;
  display: inline-block;
  padding-right: 32px;
  font-family: Plain-Medium, sans-serif;
  font-size: 14px;
  color: #253238;
}
/*.banner-error-modern > .banner-content ,*/
.banner-warning-modern > .banner-content {
  justify-content: unset;
}

.banner-warning-modern {
  background-color: #fef3e0;
  border-color: #fb9701;
}
.banner-warning-modern .maintenance-banner-icon {
  color: #fb9701;
}

.maintenance-banner-dismiss-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 5px;
  line-height: 0;
}
.maintenance-banner-dismiss-icon svg {
  height: 1.2em;
}
